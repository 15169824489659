import React from 'react';

const Summary = () => (
    <section id="summary" className="section-spacing bg-white">
        <div className="container mx-auto px-6 max-w-3xl">
            <h2 className="text-4xl font-bold mb-8 text-center gradient-text">Professional Summary</h2>
            <p className="text-lg text-gray-600 leading-relaxed text-center">
                Recent Computer Science graduate passionate about creating impactful software solutions.
                Combining technical expertise with user-centered design principles to build accessible and efficient applications.
                Experienced in full-stack development with a focus on delivering maintainable, scalable solutions.
            </p>
        </div>
    </section>
);

Summary.propTypes = {};

export default Summary;
